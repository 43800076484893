<template>
  <div class="box-globalNotification">
    <div v-if="!emailIsConfirmed">
      <div :class="`notification-item ${getClass()}`">
        <el-alert type="warning" :closable="false">
          <slot name="title">
            <div class="notifyContainer">
              <div class="notifyContent">
                <i class="fa fa-envelope" />
                <b class="ml-2 mr-2">
                  {{ $t("message.confirm_email") }}
                </b>
                <el-button type="warning" size="small" @click="sendVerifyEmail">
                  {{ $t("message.confirm_your_email") }}
                </el-button>
              </div>
            </div>
          </slot>
        </el-alert>
      </div>
    </div>
    <div :key="notification.id" v-for="notification in notifications">
      <div
        v-if="
          isNotificationVisible &&
            !ignorePages.includes(routeName) &&
            isBetweenVisibleDuration(notification.start, notification.end)
        "
        :class="`globalNotification ${getPageBackground()}`"
      >
        <div :class="`notification-item ${getClass()}`">
          <el-alert :type="notification.type" @close="closeNotification">
            <slot name="title">
              <div class="notifyContainer">
                <div class="notifyContent">
                  <i class="fas fa-chalkboard-teacher" />
                  <b class="ml-2 mr-2">{{ notification.description[lang] }} </b>
                  <router-link
                    :to="{ name: 'Balance' }"
                    v-if="notification.link.routerName"
                    class="stretchLink"
                    :href="notification.link.routerName"
                  >
                    <el-button type="success" size="small">
                      {{ notification.link.text[lang] }}
                    </el-button>
                  </router-link>
                </div>
                <!-- <el-button
                  class="ml-2"
                  :type="notification.type"
                  size="small"
                  @click="closeNotification"
                >
                  Hide
                </el-button> -->
              </div>
            </slot>
          </el-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import baseDomainPath from "@/common/baseDomainPath";
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import User from "@/apis/user";

export default {
  data() {
    return {
      lastCloseTime: null,
      routeName: ""
    };
  },
  computed: {
    ...mapState("user", ["lang", "profile"]),
    emailIsConfirmed() {
      return this.profile.email_verified_at ? true : false;
    },
    isLogin() {
      return this.$store.getters["user/token"] !== "";
    },
    isLandingPage() {
      return this.routeName === "LandingPage";
    },
    isPlanPage() {
      return this.routeName.includes("ToeflPlans");
    },
    isUserPage() {
      return this.routeName === "Profile";
    },
    isAdminUserPage() {
      return this.routeName === "ProfileForAdmin";
    },
    isResultPDF() {
      return this.routeName === "ResultPDF";
    },
    isRegisterDetailPage() {
      return this.routeName === "RegisterDetail";
    },
    isVerifyPage() {
      return this.routeName === "Verify";
    },
    ignorePages() {
      return [
        "ToeflTest",
        "MToeflTest",
        "FlashcardsVocabulariesCard",
        "MyFlashcardsVocabulariesCard",
        "FlashcardsVocabulariesList",
        "MyFlashcardsVocabulariesList"
      ];
    },
    isNotificationVisible() {
      const closeTime = this.lastCloseTime;
      if (closeTime === null) return true;

      const lastCloseTime = moment(
        moment(closeTime).format("YYYY/MM/DD 23:59:59")
      );
      const now = moment(moment().format("YYYY/MM/DD 00:00:00"));
      if (lastCloseTime.isBefore(moment(now))) {
        return true;
      }

      return false;
    },
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    period() {
      return {
        tw: "。",
        cn: "。",
        en: "."
      };
    },
    notifications() {
      return [
        {
          id: 3,
          type: "success",
          start: "2023-08-07 00:00:00",
          end: "2099-09-15 17:00:00",
          description: {
            tw: "將註冊連結分享給朋友, 註冊成功後雙方都可以獲取幣獎勵！",
            cn: "将注册链接分享给小伙伴, 注册成功后双方都可以获取币奖励！",
            en:
              "Use your invitation link to get friends to sign up. You and your friend will both get free Coins!"
          },
          link: {
            routerName: "Balance",
            text: {
              tw: "邀請聯結",
              cn: "邀请链接",
              en: "Invitation Link "
            }
          }
        }
      ];
    }
  },
  created() {
    this.setCloseTime();
    if (this.$route.name) {
      this.routeName = this.$route.name;
    } else {
      const unwatch = this.$watch(
        () => this.$route,
        async route => {
          this.routeName = route.name;
          unwatch();
        }
      );
    }
  },
  methods: {
    async sendVerifyEmail() {
      this.$confirm(
        ` ${this.$t("sat.A confirmation will be sent to your registered email")} ${this.profile.email}`,
        this.$t("message.notice"),
        {
          confirmButtonText: this.$t("message.continue"),
          cancelButtonText: this.$t("message.cancel"),
          type: "info"
        }
      )
        .then(async () => {
          await User.sendVerifyEmail({ user_id: this.profile.id });
          await this.$message({
            message: this.$t("sat.Verification email sent"),
            type: "success"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("alert.Canceled")
          });
        });
    },
    setCloseTime() {
      this.lastCloseTime = window.localStorage.getItem("notice_close_time");
    },
    closeNotification() {
      const closeTime = moment().format("YYYY/MM/DD");
      window.localStorage.setItem("notice_close_time", closeTime);
      this.lastCloseTime = closeTime;
    },
    isBetweenVisibleDuration(start, end) {
      if (start === undefined || end === undefined) return false;
      const now = moment();
      return now.isBetween(start, end);
    },
    getPageBackground() {
      if (this.isPlanPage) {
        return "planBackground";
      }

      if (this.isResultPDF) {
        return "resultPDFBackground";
      }

      return "";
    },
    getClass() {
      if (this.isPlanPage) {
        return "planContainer";
      }

      if (this.isRegisterDetailPage) {
        return "registerContainer";
      }

      if (this.isVerifyPage) {
        return "verifyContainer";
      }

      if (this.isUserPage) {
        return "userContainer";
      }

      if (this.isAdminUserPage) {
        return "adminUserContainer";
      }

      if (this.isResultPDF) {
        return "resultPDF";
      }

      if (!this.isLogin && this.isLandingPage) {
        return "landingPage";
      }

      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-alert__content {
  width: 100%;
}

.notifyContainer {
  display: flex;
  justify-content: space-between;
}
.globalNotification {
  padding: 0.5rem;
}
.globalNotification:not(:last-child) {
  padding-bottom: 0;
}
.notification-item {
}

.notifyContent {
  flex-grow: 1;
  padding: 5px 0px;
  font-size: 14px;
  margin-top: 0px;
  position: relative;
}

.stretchLink::after {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.planBackground {
  background: #f4f5f8;
}

.resultPDFBackground {
  background: #f2f3f5;
}

.planContainer {
  margin-bottom: -20px;
  max-width: 100%;
  padding: 0px 30px !important;
}

.registerContainer {
  max-width: 90%;
  padding: 0px;
  margin-bottom: 20px !important;
}

.userContainer {
  max-width: 90%;
  padding: 0px;
  margin-bottom: 0px !important;
}

.adminUserContainer {
  max-width: 95vw;
  padding: 0px 20px;
  margin-bottom: -4px !important;
}

.resultPDF {
  max-width: 1200px;
  margin-bottom: 0px !important;
  padding: 0px !important;
}

.verifyContainer {
  padding: 0px !important;
}

.landingPage {
  max-width: 100%;
  margin-bottom: 0px !important;
  padding: 0px 60px !important;
}
</style>
